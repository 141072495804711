import React from "react"
import "./Social.css"
import { SvgWrap } from "./SocialSvg"
import data from "../../utils/data"
const Social = ({ dark }) => {
  return (
    <div className={`social-bar ${dark ? "dark-socials bg-zinc-900" : ""}`}>
      <div className="social-icons iconpad text-center">
        {data.socials.map(({ social, url, targetBlank }) => (
          <a
            className="slider-nav-item"
            target={targetBlank && "_blank"}
            rel={targetBlank && "nooppener noreferrer"}
            href={url}
            key={social}
          >
            <SvgWrap social={social} />
          </a>
        ))}
      </div>
    </div>
  )
}

export default Social
