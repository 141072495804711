// export const mainImageData = graphql`
//   fragment mainImageData on File {
//     childImageSharp {
//       fluid(maxWidth: 1080) {
//         ...GatsbyImageSharpFluid

//       }
//     }
//   }
// `;
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"

export const smallImageData = graphql`
  fragment smallImageData on File {
    childImageSharp {
      gatsbyImageData(
        height: 50
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`
export const smallImageTracedData = graphql`
  fragment smallImageTracedData on File {
    childImageSharp {
      gatsbyImageData(
        height: 200
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`
export const mainImageData = graphql`
  fragment mainImageData on File {
    childImageSharp {
      gatsbyImageData(
        width: 1080
        layout: FULL_WIDTH
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`

export const useLayoutImages = () => {
  return useStaticQuery(graphql`
    query {
      layoutImages: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "backgrounds" }
        }
      ) {
        nodes {
          name
          ...mainImageData
        }
      }
      thumbnails: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "thumbnails" }
        }
      ) {
        nodes {
          name
          ...mainImageData
        }
      }
      brands: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "brands" }
        }
      ) {
        nodes {
          name
          ...smallImageTracedData
        }
      }
      navLogo: file(
        relativePath: { eq: "logo/white-compact.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        ...smallImageTracedData
      }
      navLogos: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "logo" }
        }
      ) {
        nodes {
          name
          ...smallImageTracedData
        }
      }
    }
  `)
}

export const useImage = ({ accessor, imgName }) => {
  const { [accessor]: images } = useLayoutImages()
  const [image, setImage] = React.useState(null)
  React.useEffect(() => {
    const i = imgName ? images?.nodes.find(i => i.name === imgName) : null
    setImage(i ? getImage(i) : null)
  }, [imgName, images?.nodes])
  return image
}

export const useLogo = imgName => {
  return useImage({ accessor: "navLogos", imgName })
}

export const useBackgroundImage = imgName => {
  return useImage({ accessor: "layoutImages", imgName })
}
export const useThumbnails = imgName => {
  return useImage({ accessor: "thumbnails", imgName })
}
export const useBrands = imgName => {
  return useImage({ accessor: "brands", imgName })
}
