import React from "react"

import { LongRightArrow } from "../icons"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { urls } from "../../utils/constants"
import { DocumentText, ArrowDownloadTray } from "../icons"
import data from "../../utils/data"
const Inner = ({ bgImage }) => {
  switch (bgImage) {
    case "cars":
      // green background image
      return (
        <>
          <div className="flex w-full space-x-3 max-w-lg mb-16">
            <AnchorLink
              to={urls.services}
              className="flex-1 button filled green"
            >
              Funeral Guide <LongRightArrow className="w-6 h-6" />
            </AnchorLink>
            <AnchorLink to={urls.portfolio} className="button outlined flex-1">
              Contact Us
            </AnchorLink>
          </div>
          <h3 className="title capitalize">Important Documents</h3>
          <div className="flex flex-col sm:flex-row sm:space-x-3 sm:space-y-0 space-y-3 w-full">
            {data.importantDocuments.map(
              ({ name, downloadName, file, buttonColor }) => (
                <a
                  href={file}
                  key={name}
                  download={downloadName ?? name}
                  className={`button ${
                    buttonColor ?? "green"
                  } filled rounded-lg w-full`}
                >
                  <DocumentText className="h-8 w-8" />
                  <span className="mx-auto fw-bold">{name}</span>
                  <ArrowDownloadTray />
                </a>
              )
            )}
          </div>
        </>
      )
    case 7:
      // black background image
      return (
        <div className="flex w-full space-x-3 mb-8 max-w-lg">
          <AnchorLink to={urls.contact} className="flex-1 button filled">
            Get in Touch
          </AnchorLink>
          <AnchorLink
            to={urls.portfolio}
            className="button flex-1 outlined green"
          >
            Our Portfolio
            <LongRightArrow className="w-6 h-6" />
          </AnchorLink>
        </div>
      )
    case 3:
      // red blue background image
      return (
        <div className="flex w-full space-x-3 mb-8 max-w-lg">
          <AnchorLink to={urls.contact} className="button flex-1 filled yellow">
            Get In Touch
            <LongRightArrow className="w-6 h-6" />
          </AnchorLink>
          {/* <AnchorLink to={urls.contact} className="flex-1 button outlined">
            Contact Us
          </AnchorLink> */}
        </div>
      )
    default:
      return <></>
  }
}

const IntroContent = ({ bgImage = "cars" }) => {
  return (
    <div className="w-full flex flex-col items-center">
      <Inner bgImage={bgImage} />
    </div>
  )
}

export default IntroContent
