import React from "react"
import DeclartionOfFaith from "../files/pdf/Declaration_SMFS.pdf"
import islamicWill from "../files/pdf/Will_SMFS.pdf"
import leaflet from "../files/pdf/Leaflet_SMFS.pdf"
const data = {
  productCatalogue: "http://www.cjimports.com/catalogue/edition6/",
  importantDocuments: [
    {
      name: "Declaration of Faith",
      file: DeclartionOfFaith,
      buttonColor: "yellow",
      downloadName: "Declaration of Faith SMFS",
    },
    {
      name: "Islamic Will",
      file: islamicWill,
      downloadName: "Islamic Will SMFS",
    },
    {
      name: "Our Leaflet",
      file: leaflet,
      buttonColor: "purple",
      downloadName: "Scottish Muslim Funeral Services Leaflet",
    },
  ],
  externalMentions: [
    {
      title: "Scottish Parliament",
      link: "https://www.parliament.scot/chamber-and-committees/votes-and-motions/S6M-05490",
    },
  ],
  long: {
    aboutUs: `The Scottish Muslim Funeral Services have dedicated staff and qualified volunteers required to provide a full service for men, women and children, in a manner that complies with the shariah.
    
        We endeavor to keep the cost as low as possible so you do not have to suffer unnecessary expenses at a time of bereavement.
        
        Cemetery charges depend upon the rate applied by the respective council burgh in which the chosen cemetery is located.
        
        Overseas funeral charges available upon request.`,
  },
  short: {
    // Who are we
    aboutUs: (
      <>
        Established in 2013 We provide services in
        <br /> <strong>Edinburgh</strong>, <strong>Glasgow</strong>,{" "}
        <strong>Musselburgh</strong> and throughout <strong>Scotland</strong>.{" "}
        <br />
        <br />
        We offer all inclusive services at a time of sorrow and grief.
        <br />
        <br />
        We believe knowledge is very key, we offer educational workshops for
        men, women and young adults at a local mosque near you!
      </>
    ),
  },
  services: [
    { name: "24 hour service" },
    { name: "Use of mortuary" },
    { name: "Ghusal of the deceased (final bathing)" },
    { name: "Kafan (shroud)" },
    { name: "The Supply of a suitable coffin" },
    { name: "Transport for the deceased" },
    { name: "Hearse and private ambulance" },
    { name: "Full administration" },
    { name: "Worldwide repatriation" },
    { name: "Collection and transportation of deceased" },
    { name: "Arrangements for grave and burial" },
    { name: "Arrangements for Janaazah at the mosque of your choice" },
  ],
  coffinArrangements: [
    "Headstones",
    "Floral Tributes",
    "Over Seas Repartation",
  ],
  contactDetails: {
    name: "Khurram Mustafa",
    number: "+447595313312",
    whatsappNumber: "447595313312",
    numberDisplay: "+44 7595 313 312",
    email: "info@scottishmuslimfuneralservices.co.uk",
    address: {
      name: "Scottish Muslim Funeral Services",
      street: "28 East London Street",
      city: "Edinburgh",
      postcode: "EH7 4BQ",
    },
    extra: (
      <>
        Working in partnership with{" "}
        <a
          href="http://www.wellfoundation.org.uk/"
          target="_blank"
          rel="noreferrer"
        >
          The Well Foundation
        </a>
      </>
    ),
  },
  socials: [
    {
      social: "facebook",
      url: "https://www.facebook.com/ScottishMuslimFuneralServices/",
      targetBlank: true,
    },
    {
      social: "whatsapp",
      url: "https://wa.me/+447595313312",
      targetBlank: true,
    },
    {
      social: "linkedin",
      url: "https://www.linkedin.com/company/scottish-muslim-funeral-services/",
      targetBlank: true,
    },
  ],
  testimonials: [
    {
      name: "Moulana Sohail",
      occupation: "Imam at Blackhall mosque",
      text: "Asalamualikum, on behalf of Blackhall mosque, Edinburgh I would like to thank the Scottish Muslim Funeral Service for fulfilling the needs of the Muslim community. I can say that brother Khurram has gone beyond his duty as a funeral director and fulfilled his obligation as a muslim brother at such times of difficulty. May Allah swa reward him in abundance for his efforts. Ameen",
    },
    {
      name: "Mr Rashid",
      occupation: "",
      text: "We would like to say a very big thank you for organising everything. MashAllah all went well and according to plan. It was a lovely send-off to our mum. You went above and beyond your job and we all very much appreciate it – not easy when you are dealing with the Muslim community!! You were friendly, professional, very informative and supportive of our wishes. You made a very difficult time much easy for us. May Allah bless you and your family.",
    },
    {
      name: "Wafaa Meslem",
      occupation: "",
      text: "Salam Khurram, I would like to thank you on behalf of SAFSAF family for all the support provided to us for my uncle repatriation. JazakAllahu Kheir. May Allah bless you and your family.",
    },
    {
      name: "Kashef (Reid) Bashir on behalf of Bashir Ahmed’s family",
      occupation: "",
      text: "We are appreciative of everything Khurum, and the staff at Scottish Muslim Services did in managing our fathers’ funeral. Fortunately, Khurum had the time to run through the structure, customs, and choices, which would have to be actively made prior to my father passing away. This set the tone for the funeral with Khurum effectively acting as a point of reference in situations, which required respect, tact and directed advice. This was invaluable in ensuring that one of the most stressful experiences in the life of close relatives was managed professionally and with respect to all concerned. I would actively encourage other families, in the UK and beyond, to engage Khurum and the staff at Scottish Muslim Services as early as possible in the timeline when a relative is likely to pass away.",
    },
    {
      name: "Khalid Hafeez",
      occupation: "",
      text: "I would like to thank Khurram and his team for organising my late mothers funeral, which he did very well considering he had 3 funerals on the same day. He even managed to burry my mother in the same grave beside my father (mashAllah) and ran everything on time including removing my mother’s body from the hospital. He and his team are very professional in what they do and I can’t praise them enough at a very difficult time for my family, they take all the stress and arrange everything Inc. a bronze grave plate at the grave which I thought was very good of them. I can highly recommend Scottish Muslim funeral services to all the Muslims in Scotland and we are all very grateful for their hard work.",
    },
    {
      name: "Wasi Sheikh and Family",
      occupation: "",
      text: (
        <>
          Salaam Br Khurram,
          <br />
          <br />
          A testimonial of our experience with Scottish Muslim Funeral Services.
          <br />
          <br />
          We would like to thank all the Scottish Muslim funeral services for
          your help, advice, kindness and care which you displayed before and
          after my grandmothers passing. We contacted you beforehand and were
          advised on what to do and who to call, and after that you took care of
          everything; allowing us to make the many other arrangements.
          <br />
          <br />
          I would also like to thank you as it didn’t matter when we called or
          messaged you were always happy to help, whether it was on the weekend
          or in the middle of the night. The peace of mind that your support
          provided gave my family a lot of relief as we knew we were in good
          hands.
          <br />
          <br />
          May Allah reward you for your efforts and enable you to continue
          helping those in need of your assistance.
          <br />
          <br />
          Jazak’Allah
        </>
      ),
    },
    {
      name: "Khashaf Aziz",
      occupation: "",
      text: "Thank you for all your help. I am very proud and lucky to have a friend that does such a difficult but rewarding job. May Allah bless you and your family.",
    },
    {
      name: "Sudabeh Moti",
      occupation: "",
      text: (
        <>
          Hi Brother Mustafa, on behalf of my family and myself would like to
          thank you for organising the service and making sure everything is
          going smoothly. We believe all went well in a way that my father
          deserved it. He was a great, intelligent man and now resting in peace.
          Thank you so much.
          <br />
          <br />
          God bless you
        </>
      ),
    },
    {
      name: "Shabena Ahmed",
      occupation: "",
      text: (
        <>
          The service was compassionate and understanding.
          <br />
          At any time we felt lost or uncertain of what to do, it was a great
          relief to know there was someone approachable and understanding on the
          other line…
          <br />
          <br />
          I can highly recommend the service and give brothers duas for his help
          during this difficult time…
          <br />
          <br />
          May Allah bless your endeavours
        </>
      ),
    },
    {
      name: "Mustafa",
      occupation: "",
      text: (
        <>
          Salaam
          <br />
          I would like to thank you again for your efforts in helping ensure my
          mother reached her resting place without delay.
          <br />
          <br />
          May Allah swt bless you all.
        </>
      ),
    },
    {
      name: "Husnain, Whitburn",
      occupation: "",
      text: "Salaam uncle, thank-you for all your help with everything. We always questioned what the process is for everything but you made it very simple and straightforward.",
    },
    {
      name: "Anila Butt",
      occupation: "",
      text: (
        <>
          JazakAllah Khair for all your help may Allah Pak give you Ajar e
          Azeem.
          <br />
          <br />
          Ameen Ya RubilAlameen.
        </>
      ),
    },
    {
      name: "Dawuid Chaudhry and family",
      occupation: "",
      text: "SMFS brought a lot of peace to my family, especially myself, the respect, dignity and honour he showed my father was amazing.",
    },
    {
      name: "Abdul Rashid",
      occupation: "",
      text: "We would like to say a very big thank you to you for organising everything. MashAllah all went well and according to plan. It was a lovely send-off to our mum. You went above + beyond your job and we all very much appreciate it. You were friendly, professional, very informative and supportive of our wishes. You made a very difficult time much easier for us. May Allah bless you and your family.",
    },
    {
      name: "Mehboob Uddin",
      occupation: "",
      text: "When my mother Montera Bibi passed away brother Khurram came to the hospital taken ownership he spoke to the nurse in charge and made arrangement to remove my mother from the hospital. This was done so quick and efficiently within few hours. I didn’t have to worry as I felt my mother was in good hands, Thank you for the excellent professional service provided by Scottish Muslim Funeral Services.",
    },
  ],
}

export default data
