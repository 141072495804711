// import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { ChevronLeft, ChevronRight } from "./icons"
import { useInterval } from "../utils/useInterval"
import useInViewport from "../utils/useInViewport"
const SliderControl = ({ onClick, isLeft, disabled }) => (
  <button
    type="button"
    onClick={disabled ? null : onClick}
    disabled={disabled}
    className={`absolute text-white text-2xl z-10 bg-black h-10 w-10 rounded-full opacity-50 hover:opacity-25 flex items-center justify-center z-10 ${
      isLeft ? "left-6" : "right-6"
    }`}
    style={{ top: "40%" }}
  >
    w
    <span role="img" aria-label={`Arrow ${isLeft ? "left" : "right"}`}>
      {/* {isLeft ? "◀" : "▶"} */}
      {isLeft ? <ChevronLeft /> : <ChevronRight />}
    </span>
  </button>
)

const Carousel = ({ images }) => {
  const [currentImage, setCurrentImage] = React.useState(0)
  const [isCarouselHover, setIsCarouselHover] = React.useState(false)
  const carouselRef = React.useRef(null)
  const carouselInView = useInViewport(carouselRef)
  const refs = images.reduce((acc, val, i) => {
    acc[i] = React.createRef()
    return acc
  }, {})
  const onClick = previous => () => {
    let i = previous ? currentImage - 1 : currentImage + 1
    if (i > images.length - 1 || i < 0) {
      i = 0
    }
    if (i === null) {
      return null
    }
    setCurrentImage(i)
  }
  useInterval(
    () => onClick()(),
    isCarouselHover || !carouselInView ? null : 5000
  )
  return (
    <div
      onMouseEnter={() => setIsCarouselHover(true)}
      onMouseLeave={() => setIsCarouselHover(false)}
      className="flex justify-center items-center"
      ref={carouselRef}
    >
      <div className="relative w-full">
        <div className="carousel flex flex-row overflow-hidden">
          <SliderControl isLeft onClick={onClick(true)} />
          {images.map((img, i) => (
            <div
              className="w-full flex-shrink-0 z-30"
              style={{
                transform: `translateX(-${currentImage * 100}%)`,
                transition: "all 0.8s",
              }}
              key={i}
              ref={refs[i]}
            >
              <div className="w-full object-contain">{img}</div>
            </div>
          ))}
          <SliderControl onClick={onClick()} />
        </div>
      </div>
    </div>
  )
}

export default Carousel
