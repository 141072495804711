import IntroContent from "../components/home/IntroContent"

const topAnchorId = "back-to-top-anchor"
// const uri = "http://private.forwardsafe.co.uk:8064";
const uri = "http://192.168.0.56:8072"
//const uri = "https://www.mosquekitchen.com/api"

export const urls = {
  home: "/",
  aboutUs: "/about-us/",
  services: "/services/",
  funeralGuide: "/muslim-funeral-guide/",
  adviceSupport: "/advice-support/",
  education: "/education-workshop/",
  products: "/products/",
  contact: "/contact/",
}

const pages = [
  {
    to: urls.home,
    // text:"Contact Us"
    // linkDisplay:
    // displayTitle: "About Us",
    title: "Home",
    description: "",
    layoutImage: "cars",
    displayTitle: "Scottish Muslim Funeral Services",
    displaySubtitle:
      "Serving the Muslim Community of Scotland at a time of sorrow and grief",
    displayComponent: IntroContent,
  },
  {
    to: urls.aboutUs,
    title: "About",
    displayTitle: "About Us",
    description: "",
    layoutImage: "kabbah",
  },

  {
    to: urls.services,
    title: "Services",
    displayTitle: "Services",
    description: "",
    layoutImage: "haram",
  },
  {
    to: urls.funeralGuide,
    title: "Guide",
    // hideFromMainNav: true,
    description: "",
    displayTitle: "Muslim Funeral Guide",
    layoutImage: "sheikh-zayed",
  },
  {
    to: urls.adviceSupport,
    title: "Support",
    description: "",
    displayTitle: "Advice & Support",
    layoutImage: "holy-quran",
  },
  {
    to: urls.education,
    title: "Education",
    description: "",
    // hideFromMainNav: true,
    displayTitle: "Education Workshop",
    layoutImage: "msq-white",
  },
  {
    to: urls.products,
    title: "Products",
    description: "",
    displayTitle: "Products",
    displaySubtitle: "Explore our range of products ready to be customised",
    layoutImage: "GCM",
  },
  {
    to: urls.contact,
    title: "Contact",
    description: "",
    displayTitle: "Contact Us",
    layoutImage: "pillars",
  },
]

const constants = {
  // gqlUri:"https://www.mosquekitchen.com/api/graphql"
  uri: uri,
  gqlUri: `${uri}/graphql`,
  formatMediaUrl: url => `${uri}/media/${url}`,
  topAnchorId,
  genTopAnchorLink: path => `${path ?? ""}#${topAnchorId}`,
  navbarOffset: -64,
  navPadding: 64,
  seo: Object.assign({}, ...pages.map(({ to, ...x }) => ({ [to]: x }))),
  navLinks: pages
    .filter(p => !p?.hideFromMainNav)
    .map(page => ({
      to: page.to,
      title: page.title,
      text: page.linkDisplay ?? page.title,
      extendedMenu: [
        "/muslim-funeral-guide",
        "/education-workshop",
        "/advice-support",
      ].includes(page.to),
    })),
  navDrawerLinks: pages
    // .filter(p => !p?.hideFromMainNav)
    .map(page => ({
      to: page.to,
      title: page.title,
      text: page.linkDisplay ?? page.title,
      extendedMenu: [
        "/muslim-funeral-guide",
        "/education-workshop",
        "/advice-support",
      ].includes(page.to),
    })),
  testimonialInlineLimit: 8,
  useParallax: false,
}
// module.exports = constants
export default constants
// export { constants as default };
