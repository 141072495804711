import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useBackgroundImage } from "../../utils/queries"
import { Parallax } from "react-scroll-parallax"
import constants from "../../utils/constants"
const BackgroundImageWrap = ({ children, parallax, introSmall }) => {
  const className = `absolute w-full h-full top-0`
  // md: top - auto
  if (!parallax) return <div className={className}>{children}</div>
  return (
    <Parallax speed={-50} className={className}>
      {children}
    </Parallax>
  )
}
const Section = ({
  title,
  children,
  titleComponent: TitleComponent = "h3",
  bgImage,
  bgImageAlt,
  bgOverlayDarker,
  subtitle,
  navPadding,
  intro,
  introSmall,
  className,
  maxWidth,
  alignLeft,
  titleLeft,
  parallax = constants.useParallax,
  titleClass,
  useDiv,
}) => {
  const layoutBackgroundImage = useBackgroundImage(bgImage)
  const C = useDiv ? "div" : "section"
  return (
    <C
      className={`relative mx-auto flex flex-col items-center justify-center  ${
        introSmall
          ? "pt-32 pb-6"
          : navPadding
          ? "pb-16 pt-48 md:pt-32"
          : children
          ? "py-12"
          : "pt-6 pb-4"
      } ${
        layoutBackgroundImage
          ? "use_bg_image shadow-inner z-20 overflow-hidden"
          : "bg-white z-30"
      } ${intro ? "min-h-[100vh]" : introSmall ? "" : ""} ${className ?? ""}`}
    >
      <div
        className={`z-20 flex flex-col items-center ${
          alignLeft ? "text-left" : "text-center"
        } px-4 ${maxWidth ? "max-w-3xl" : ""} ${navPadding ? "h-full" : ""} ${
          bgImage ? "text-white" : ""
        }`}
      >
        {title && (
          <TitleComponent
            className={`title capitalize ${titleClass ?? ""} ${
              titleLeft ? "text-left" : "text-center"
            }`}
          >
            {title}
          </TitleComponent>
        )}
        <p>{subtitle}</p>
        {children}
      </div>
      {layoutBackgroundImage && (
        <>
          <BackgroundImageWrap introSmall parallax={parallax}>
            <GatsbyImage
              image={layoutBackgroundImage}
              alt={bgImageAlt ?? title}
              className="z-0 h-full"
              // style={{ position: absolute }}
            />
          </BackgroundImageWrap>
          <div
            className={`h-full w-full absolute z-0 top-0 max-h-[100vh] ${
              bgOverlayDarker ? "bg-black/[.6]" : "bg-black/[.4]"
            }`}
          ></div>
        </>
      )}
    </C>
  )
}

export default Section
