import React from "react"
import Navbar from "../nav/Navbar"
import constants from "../../utils/constants"
import Section from "./Section"
import { StaticImage } from "gatsby-plugin-image"
import data from "../../utils/data"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { urls } from "../../utils/constants"
import { LocationIcon, PhoneIcon, MailIcon } from "../icons"
import Carousel from "../Carousel"
import Social from "./Social"
const Layout = ({ children, location, path, ssr = false }) => {
  const [page, setPage] = React.useState(null)
  const isRootPath = location.pathname === `${__PATH_PREFIX__}/`
  React.useEffect(() => {
    // const pa =
    //   path.length > 1 && path.slice(-1) === "/" ? path.slice(0, -1) : path
    setPage(path ? constants.seo[path] : null)
  }, [path])
  // const footerLogo = useLogo("white-compact")
  const fixedNav = isRootPath || page?.layoutImage
  return (
    <>
      <a
        href="#main-content"
        style={{
          position: "absolute",
          transform: "translateY(-120%)",
          transition: "all .3s",
          border: "1px solid #09f",
          top: 5,
          left: 5,
          backgroundColor: "#fff",
          borderRadius: 5,
          zIndex: 9999,
          padding: 3,
        }}
        title="Skip Navigation"
      >
        Skip Navigation
      </a>
      <Navbar
        path={path}
        isBrowser={!ssr}
        className={`z-40`}
        fixed={fixedNav}
      />
      <main id="main-content z-30">
        {page &&
          (page.carousel ? (
            <Carousel
              images={page.carousel.map(caro => (
                <Section
                  navPadding
                  intro
                  useDiv
                  titleComponent="h2"
                  key={page.title}
                  {...caro}
                />
              ))}
            />
          ) : (
            <Section
              bgImage={page.layoutImage}
              bgImageAlt={page.layoutImageAlt ?? page.layoutImage}
              bgOverlayDarker={!fixedNav}
              title={page.displayTitle}
              titleComponent="h2"
              subtitle={page.displaySubtitle}
              navPadding={fixedNav}
              intro={isRootPath}
              introSmall={fixedNav}
            >
              {page.displayComponent && (
                <div className="py-4 w-full">
                  <page.displayComponent />
                </div>
              )}
            </Section>
          ))}
        {children}
      </main>
      <footer className=" w-full bg-zinc-900 text-white">
        <div className="max-w-5xl mx-auto px-5">
          <div className="pb-4 border-b-2 border-zinc-600">
            <div className="w-100 pt-4">
              <Social dark />
            </div>
            <div className="flex w-100 justify-center items-center md:items-start flex-col md:flex-row pb-4 pt-3">
              {/* <div className="">
                <h6>WHO WE ARE</h6>
                <p className="text-xs">{data.short.aboutUs}</p>
              </div> */}
              <div className="mb-3 md:mb-0">
                <a
                  href="https://www.google.com/search?q=scottish+muslim+funeral+services&oq=scottish+muslim+funeral&aqs=chrome.0.0i355i512j46i175i199i512j69i57j0i390i650l2j69i60l2j69i61.2977j0j7&sourceid=chrome&ie=UTF-8#"
                  target="_blank"
                  rel="noreferrer noopenner"
                  className="flex"
                >
                  <LocationIcon className="mr-3" />{" "}
                  <p>
                    {data.contactDetails.address.name}
                    <br />
                    {data.contactDetails.address.street}
                    <br />
                    {data.contactDetails.address.city}{" "}
                    {data.contactDetails.address.postcode}
                  </p>
                </a>
              </div>
              <div className="md:ml-6">
                <a
                  className="flex mb-3"
                  href={`mailto:${data.contactDetails.email}`}
                >
                  <MailIcon className="mr-3" />
                  {data.contactDetails.email}
                </a>
                <a className="flex" href={`tel:${data.contactDetails.number}`}>
                  <PhoneIcon className="mr-3" />
                  {data.contactDetails.numberDisplay}
                </a>
              </div>
            </div>

            <div className="md:h-12 my-6 flex flex-col md:flex-row justify-center items-center">
              <AnchorLink to={urls.home} className="md:mr-8 mb-5 md:mb-0">
                {/* <GatsbyImage
                    image={footerLogo}
                    alt="Scottish Muslim Funeral Services"
                    className="w-full h-full"
                    objectFit="contain"
                  /> */}
                <StaticImage
                  src="../../images/logo/white-compact.png"
                  alt="SMFS"
                  className="max-h-full"
                  // width={280}
                  height={50}
                />
              </AnchorLink>
              <a
                href="https://www.wellfoundation.org.uk/"
                rel="noreferrer noopenner"
                target="_blank"
              >
                <StaticImage
                  src="../../images/brands/thewellfoundation1.png"
                  alt="The Well Foundation"
                  className="max-h-full"
                  height={50}
                />
              </a>
            </div>
            <div className="text-center flex flex-col justify-center  items-center">
              <p className="text-xs">
                Working in partnership with{" "}
                <a
                  href="https://www.wellfoundation.org.uk/"
                  rel="noreferrer noopenner"
                  target="_blank"
                >
                  The Well Foundation
                </a>
              </p>
            </div>
          </div>
          <div className="flex flex-row p-4 justify-center items-center w-full">
            <p className="text-xs text-center text-zinc-400 ">
              © Scottish Muslim Funeral Services | Designed by{" "}
              <a
                href="https://designs.forwardsafe.co.uk"
                target="_blank"
                rel="noreferrer"
              >
                Forwardsafe Designs
              </a>
            </p>
          </div>
        </div>
      </footer>
      <div
        style={{ position: "absolute", top: 0 }}
        id={constants.topAnchorId}
      ></div>
    </>
  )
}

export default Layout
