import React from "react"
import ScrollTop from "./ScrollTop"
import constants, { urls } from "../../utils/constants"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { StaticImage } from "gatsby-plugin-image"
import { useScrollTrigger } from "../../utils/useScrollPosition"
import NavDrawer from "./NavDrawer"
import "./navbar.css"

const NavLink = ({ path, page: { to, text, title } }) => {
  return (
    <AnchorLink
      to={to}
      title={title}
      className={`nav__link ${path === to ? "font-bold nav__link__hover" : ""}`}
    >
      {text}
    </AnchorLink>
  )
}

const Navbar = ({ path, isBrowser, className, fixed }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const handleOpenNavMenu = () => {
    setDrawerOpen(true)
  }
  const toggleDrawer = open => event => {
    setDrawerOpen(open ?? !drawerOpen)
  }
  const useScrollNav = useScrollTrigger({
    // disableHysteresis: true,
    threshold: 15,
  })
  const useScrollNavV = !fixed || useScrollNav
  return (
    <>
      <NavDrawer
        toggleDrawer={toggleDrawer}
        drawerOpen={drawerOpen}
        path={path}
      />
      <nav
        className={`${className ?? ""} sm:px-3 transition-all ${
          useScrollNavV ? "navbg useScrollNav shadow-lg py-3" : "py-7"
        } ${fixed ? "fixed" : "sticky"} top-0 inset-x-0`}
      >
        <div className="max-w-6xl flex flex-wrap items-center relative justify-center mx-auto">
          <div className="flex align-items-center align-content-center">
            <button
              type="button"
              onClick={handleOpenNavMenu}
              className="absolute inset-y-0 sm:left-3 inline-flex items-center justify-center p-2 text-sm text-white hover:text-grey-600 rounded-lg lg:hidden hover:bg-black/[.3] transition-all focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navdrawer"
              aria-expanded={drawerOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div className="w-full">
            <ul
              className={`mx-auto font-medium transition-all duration-500 flex flex-row justify-center items-center text-center `}
            >
              {constants.navLinks.map((page, index) => (
                <React.Fragment key={page.to}>
                  {Math.trunc(constants.navLinks.length / 2) === index && (
                    <li className="drop-shadow-xl mx-auto">
                      <AnchorLink to={urls.home}>
                        <StaticImage
                          src="../../images/logo/white-compact.png"
                          alt="SMFS"
                          className={`nav-transition drop-shadow-lg ${
                            useScrollNav
                              ? "max-w-[210px] my-1"
                              : "max-w-[280px]"
                          }`}
                          width={280}
                        />
                      </AnchorLink>
                    </li>
                  )}
                  <li
                    className={`hidden lg:block transition-all duration-500 px-4 ${
                      constants.navLinks.length / 2 >= index + 1
                        ? "left__link"
                        : "right__link"
                    }`}
                  >
                    <NavLink path={path} page={page} />
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      </nav>
      <ScrollTop />
    </>
  )
}

export default Navbar
